import React, { useMemo } from 'react'

import { Card, Typography, Grid, LinearProgress } from '@barracuda-internal/bds-core'

import {
  Grid as DataTable,
  GridColumn as DataTableColumn,
  GridNoRecords as DataTableNoRecords
} from '@progress/kendo-react-grid'

import Cell from 'global/components/lib/grid/cell/Cell'
import CellText from 'global/components/lib/grid/cell/CellText'
import CellLabelType from 'global/components/lib/grid/cell/CellLabelType'
import AttackTypeLabel from 'global/components/lib/attackTypeLabel/AttackTypeLabel'
import { formatDate } from 'global/lib/datetime'
import { ThreatLog } from 'global/types/api/scan'
import { useFormatMessage } from 'global/lib/localization'

import useDashboardHeaderLogic from 'ets/components/pages/dashboard/header/useDashboardHeaderLogic'
import styles from 'ets/components/pages/dashboard/header/dashboardHeaderStyles'
import { ModifiedScanStats, SCAN_STATUSES } from 'ets/components/pages/dashboard/useDashboardLogic'

const BASE_I18N_KEY = 'ets.app.dashboard.header'
const BASE_I18N_TABLE_KEY = 'ets.app.data_tables.threat_log'

const MAX_ITEMS_IN_PROGRESS_AREA = 6

type DashboardHeaderProp = {
  scanStatus: string
  scan: ModifiedScanStats | undefined
}

export const DashboardHeader: React.FC<DashboardHeaderProp> = ({ scanStatus, scan }) => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const formatMessageTable = useFormatMessage(BASE_I18N_TABLE_KEY)
  const classes = styles()
  const [dashboardHeaderLogic] = useDashboardHeaderLogic()

  return useMemo(() => {
    const {
      GRID_COLUMNS,
      columnsConfig,
      isTimeDurationDisabled,
      shouldRenderScanDetails,
      threatLog
    } = dashboardHeaderLogic

    return (
      <Grid container justifyContent="center" className={classes.root} data-field="root-grid">
        <Card className={classes.headerCard} elevation={1}>
          <Grid item xs={12} className={classes.progressArea} data-field="progress-area">
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              className={classes.progressWrapper}
              data-field="progress-wrapper"
            >
              {shouldRenderScanDetails && (
                <>
                  <Grid container direction="row" data-field="scan-details" justifyContent="space-between">
                    <Grid item xs={2}>
                      <Typography align="left" className={classes.statusTitle}>
                        {formatMessage('scan_status')}
                      </Typography>
                      <Typography align="left" className={classes.largerBlackText}>
                        {formatMessage(scanStatus)}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography align="left" className={classes.statusTitle}>
                        {formatMessage(`${scanStatus === SCAN_STATUSES.COMPLETED ? 'completed_on' : 'started_on'}`)}
                      </Typography>
                      <Typography align="left" className={classes.largerBlackText}>
                        {scanStatus === SCAN_STATUSES.PREPARING && '--'}
                        {scanStatus === SCAN_STATUSES.IN_PROGRESS && scan?.triggeredOn}
                        {scanStatus === SCAN_STATUSES.COMPLETED && scan?.finishedOn}
                      </Typography>
                    </Grid>
                    {!isTimeDurationDisabled && (
                      <Grid item xs={4}>
                        <Typography align="left" className={classes.statusTitle}>
                          {formatMessage(
                            `${scanStatus === SCAN_STATUSES.COMPLETED ? 'scan_duration' : 'time_remaining'}`
                          )}
                        </Typography>
                        <Typography align="left" className={classes.largerBlackText}>
                          {scanStatus === SCAN_STATUSES.PREPARING && '--'}
                          {scanStatus === SCAN_STATUSES.IN_PROGRESS && scan?.remaining}
                          {scanStatus === SCAN_STATUSES.COMPLETED && scan?.duration}
                        </Typography>
                      </Grid>
                    )}
                    {isTimeDurationDisabled && (
                      <Grid item xs={4} className={classes.timeDurationWrapper}>
                        <Typography align="left" className={classes.timeDuration}>
                          {formatMessage('scan_estimate_text')}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={1}>
                      <Typography align="left" className={classes.statusTitle}>
                        {formatMessage('emails_scanned')}
                      </Typography>
                      <Typography align="left" className={classes.largerBlackBoldText}>
                        {scanStatus === SCAN_STATUSES.PREPARING ? '--' : scan?.emailCount.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs="auto" className={classes.rightContent}>
                      <Typography align="left" className={classes.statusTitle}>
                        {formatMessage('threats_detected')}
                      </Typography>
                      <Typography align="left" className={classes.largerRedBoldText}>
                        {scanStatus === SCAN_STATUSES.PREPARING ? '--' : scan?.spAttackCount.toLocaleString()}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <LinearProgress
                      className={classes.linearProgress}
                      value={scanStatus === SCAN_STATUSES.COMPLETED ? 100 : scan?.percentageCompleted}
                      variant={scanStatus === SCAN_STATUSES.PREPARING ? 'indeterminate' : 'determinate'}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {shouldRenderScanDetails &&
            (scanStatus === SCAN_STATUSES.IN_PROGRESS || scanStatus === SCAN_STATUSES.PREPARING) && (
              <Grid item xs={12} className={classes.tableArea} data-field="table-area">
                <Typography className={classes.tableTitle}>{formatMessage('threat_emails_found')}</Typography>
                <DataTable
                  {...({} as any)} // HACK: This is a workaround to avoid TS error
                  className={classes.dataTable}
                  data={[...threatLog].reverse().slice(0, MAX_ITEMS_IN_PROGRESS_AREA)}
                >
                  <DataTableNoRecords>
                    <Typography className={classes.noRecordText}>{formatMessage('no_threats_detected')}</Typography>
                  </DataTableNoRecords>
                  <DataTableColumn
                    width={120}
                    field={GRID_COLUMNS.RECEIVED}
                    {...columnsConfig[GRID_COLUMNS.RECEIVED]}
                    title={formatMessageTable('received')}
                    cell={({ dataItem }: { dataItem: ThreatLog }) => (
                      <Cell>
                        <CellText className={classes.text}>{formatDate(dataItem.date)}</CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={GRID_COLUMNS.EMPLOYEE}
                    {...columnsConfig[GRID_COLUMNS.EMPLOYEE]}
                    title={formatMessageTable('employee')}
                    cell={({ dataItem }: { dataItem: ThreatLog }) => (
                      <Cell>
                        <CellText ellipsis className={classes.blackBoldText}>
                          {dataItem.identity}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={GRID_COLUMNS.FROM}
                    {...columnsConfig[GRID_COLUMNS.FROM]}
                    title={formatMessageTable('from')}
                    cell={({ dataItem }: { dataItem: ThreatLog }) => (
                      <Cell data-field="data-cell">
                        <CellText ellipsis className={classes.blackText}>
                          {dataItem.sender}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    field={GRID_COLUMNS.SUBJECT}
                    {...columnsConfig[GRID_COLUMNS.SUBJECT]}
                    title={formatMessageTable('subject')}
                    cell={({ dataItem }: { dataItem: ThreatLog }) => (
                      <Cell>
                        <CellText ellipsis className={classes.blackText}>
                          {dataItem.subject}
                        </CellText>
                      </Cell>
                    )}
                  />
                  <DataTableColumn
                    width={200}
                    field={GRID_COLUMNS.ATTACK_TYPES}
                    {...columnsConfig[GRID_COLUMNS.ATTACK_TYPES]}
                    title={formatMessageTable('attack_types')}
                    cell={({ dataItem }: { dataItem: ThreatLog }) => (
                      <Cell>
                        <CellLabelType>
                          <AttackTypeLabel attackType={dataItem.taxonomy} />
                        </CellLabelType>
                      </Cell>
                    )}
                  />
                </DataTable>
              </Grid>
            )}
        </Card>
      </Grid>
    )
  }, [
    classes,
    dashboardHeaderLogic,
    formatMessage,
    formatMessageTable,
    scan?.triggeredOn,
    scan?.finishedOn,
    scan?.remaining,
    scan?.duration,
    scan?.emailCount,
    scan?.spAttackCount,
    scan?.percentageCompleted,
    scanStatus
  ])
}

export default DashboardHeader
