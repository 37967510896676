import { useMemo, useEffect, useCallback, useRef, useReducer } from 'react'
import { Duration } from 'luxon'

import { IconButtonProps, MenuProps } from '@barracuda-internal/bds-core'

import * as analyticsLib from 'global/lib/analytics/analyticsService'
import { formatDateWithTime, humanizeDuration, isAfterDate, timeDifference } from 'global/lib/datetime'
import useDialogLogic from 'global/lib/dialogs/useDialogLogic'
import useAccessTokenLib from 'global/lib/accessToken/useAccessToken'
import { getScanStats, getScanThreatLog } from 'global/redux/features/scan/scanSlice'
import { isPending } from 'global/redux/toolkit/api'
import { ScanStats } from 'global/types/api/scan'
import styles, { NAV_BAR_HEIGHT } from 'global/components/lib/layout/layoutStyles'

import { getEmployeesReport } from 'ets/redux/features/reports/employees/employeesSlice'
import { getThreatsReport } from 'ets/redux/features/reports/threats/threatsSlice'
import { getDomainsReport } from 'ets/redux/features/reports/domains/domainsSlice'
import { getAttacksByDomainReport } from 'ets/redux/features/reports/attacksByDomain/attacksByDomainSlice'
import { getChartsReports } from 'ets/redux/features/reports/chartReport/chartReportSlice'
import { useAppDispatch, useAppSelector } from 'ets/redux/toolkit/hooks'
import {
  activateReportSharing,
  deactivateReportSharing,
  deleteEtsReport
} from 'ets/redux/features/settings/settingsApiThunks'
import { IsUserInputDisabledForTable } from 'ets/components/pages/dashboard/isUserInputDisabledForTableType'
import routesConfig from 'ets/lib/routes/routesConfig'

export const SCAN_STATUSES = {
  COMPLETED: 'scan_completed',
  IN_PROGRESS: 'scan_in_progress',
  PREPARING: 'scan_preparing'
}

export interface ModifiedScanStats extends ScanStats {
  duration: string
  finishedOn: string
  name: string | undefined
  percentageCompleted: number
  remaining: string
  threatsDetected: number
  triggeredOn: string
}

export interface OptionsMenu {
  action: () => void
  disabled: boolean
  id: string
}
export interface DashboardLogic {
  error?: string | false
  fixTableHeight: (tableId: string, newHeight: number) => void
  getSectionElement: (section: string) => HTMLElement | null
  getShareLinkExpiration: () => string
  getReportShareLink: () => string
  isShareChecked: boolean
  isShareInProgress: boolean
  inProgress: boolean
  isButtonHidden: boolean
  isDeleteDialogOpened: boolean
  isDeleteInProgress: boolean
  isUserInputDisabledForTable: IsUserInputDisabledForTable
  moreInfoButtonMenuOptions: {
    iconButtonProps: IconButtonProps
    menuItems: OptionsMenu[]
    menuProps: MenuProps
  }
  onDeleteScan: () => void
  onShare: () => void
  onToggleDeleteDialog: () => void
  scan: ModifiedScanStats | undefined
  scanStatus: string
  scrollToSection: (sectionIndex: number) => void
  sendShareTrackingEvent: () => void
}

export type State = {
  anchorOptionsMenu: React.SyntheticEvent['currentTarget'] | undefined
}

export const TABS = {
  OVERVIEW: 'overview',
  EMPLOYEES: 'employees',
  THREATS: 'threats',
  DOMAINS: 'domains',
  SOURCES_OF_ATTACKS: 'attacks'
}

export const APP_HEADER_HEIGHT = NAV_BAR_HEIGHT

export default function useDashboardLogic(): [DashboardLogic] {
  const isScanStartedToLoad = useRef(false)
  const dispatch = useAppDispatch()
  const [isDeleteDialogOpened, toggleDeleteDialog] = useDialogLogic()
  const [accessTokenLib] = useAccessTokenLib()
  const classes = styles()
  const {
    accessToken,
    accessTokenId,
    error,
    isDeleteInProgress,
    isInitialScanStatsLoaded,
    isReportsInProgress,
    isScanInProgress,
    isShareSecretSet,
    isShareInProgress,
    isShareValid,
    isStatsInProgress,
    scan
  } = useAppSelector(_stores => ({
    accessToken: _stores.accessToken?.accessToken,
    accessTokenId: _stores.accessToken.accessToken?.id || '',
    error: _stores.report.error,
    isDeleteInProgress: isPending(_stores.settings.deleteEtsReportApiStatus),
    isInitialScanStatsLoaded: _stores.scan.isInitialScanStatsLoaded,
    isReportsInProgress: isPending(_stores.reports.employees.apiStatus),
    isScanInProgress: _stores.scan.stats?.id ? _stores.scan.stats.inProgress : true,
    isShareSecretSet: !!_stores.accessToken.shareSecret?.value,
    isStatsInProgress: !!_stores.scan.stats?.inProgress,
    isShareInProgress:
      isPending(_stores.settings.activateReportSharingApiStatus) ||
      isPending(_stores.settings.deactivateReportSharingApiStatus),
    isShareValid: isAfterDate({ initialDate: _stores.accessToken.accessToken?.reportSecret?.expires }),
    scan: _stores.scan
  }))

  const [state, setState] = useReducer((_state: State, newState: Partial<State>) => ({ ..._state, ...newState }), {
    anchorOptionsMenu: undefined
  })

  // check query params only on init
  useEffect(() => {
    if (window.location.toString().includes(analyticsLib.QUERY_PARAMS.PDF)) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_PDF, {
        url: window.location.href
      })
    }
  }, [])

  useEffect(() => {
    // user is logged in but the report is being opened from email
    if (window.location.toString().includes('emailscancomplete')) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.REPORT_OPENED_IN_EMAIL, {
        url: window.location.href
      })
      routesConfig.DASHBOARD.goto({ reportId: accessTokenId })
    }

    if (!isScanStartedToLoad.current) {
      isScanStartedToLoad.current = true
      dispatch(getScanStats())
      dispatch(getScanThreatLog())
    }

    if (isStatsInProgress)
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.VIEW_SCAN_IN_PROGRESS, { url: window.location.href })

    if (isInitialScanStatsLoaded && !isScanInProgress && !isReportsInProgress) {
      dispatch(getChartsReports({ accessTokenId }))
      dispatch(getEmployeesReport({ accessTokenId }))
      dispatch(getThreatsReport({ accessTokenId }))
      dispatch(getDomainsReport({ accessTokenId }))
      dispatch(getAttacksByDomainReport({ accessTokenId }))

      analyticsLib.trackAppEvent(analyticsLib.EVENTS.DASHBOARD_VIEW, { accessTokenId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInitialScanStatsLoaded, isScanInProgress, accessTokenId, isStatsInProgress])

  const inProgress = useMemo(() => {
    return !isInitialScanStatsLoaded
  }, [isInitialScanStatsLoaded])

  const isUserInputDisabledForTable: IsUserInputDisabledForTable = useCallback(
    (tableData, reportData, isReportLoaded) => {
      const { totalCount } = reportData.report
      const isDataFiltered = !!tableData.search.length || !!tableData.filter

      return !isReportLoaded || (isReportLoaded && !totalCount && !isDataFiltered)
    },
    []
  )

  const getSectionElement = useCallback((section: string): HTMLElement | null => {
    return document.getElementsByClassName(`${section}-section`)[0] as HTMLElement | null
  }, [])

  const fixTableHeight = useCallback((tableId: string, newHeight: number) => {
    const table = document.getElementsByClassName(`${tableId}-table`)[0] as HTMLElement | null

    if (table) {
      table.style.height = `${newHeight}px`
    }
  }, [])

  const scrollToSection = useCallback(
    (sectionIndex: number) => {
      const sectionElement = getSectionElement(Object.values(TABS)[sectionIndex])
      // Get the scrollable parent element
      const mainElement = document.querySelector(`.${classes.content}`)

      if (sectionElement) {
        if ('scrollBehavior' in document.documentElement.style && mainElement) {
          mainElement.scroll({
            top: sectionElement.offsetTop - APP_HEADER_HEIGHT,
            behavior: 'smooth'
          })
        } else {
          window.scroll(0, sectionElement.offsetTop)
        }
      }
    },
    [classes, getSectionElement]
  )

  const scanData: ModifiedScanStats | undefined = useMemo(() => {
    const { stats } = scan

    if (!stats.id) {
      return undefined
    }

    const spentTime = Math.round(timeDifference({ subtractedDate: stats.createdOn }).as('minute'))
    const totalTime = spentTime + stats.scanTimeRemaining

    return {
      ...stats,
      name: accessToken?.name,
      triggeredOn: formatDateWithTime(stats.createdOn),
      finishedOn: formatDateWithTime(stats.finishedOn),
      duration: humanizeDuration(
        timeDifference({ initialDate: stats.finishedOn, subtractedDate: stats.createdOn }).milliseconds
      ),
      threatsDetected: stats.spAttackCount,
      remaining: humanizeDuration(Duration.fromObject({ minutes: stats.scanTimeRemaining }).as('millisecond')),
      percentageCompleted: stats.scanTimeRemaining ? Math.floor((spentTime / totalTime) * 100) : 0
    }
  }, [scan, accessToken])

  const scanStatus = useMemo(() => {
    if (!scanData?.scanTimeRemaining) {
      return SCAN_STATUSES.PREPARING
    }
    if (scanData?.inProgress) {
      return SCAN_STATUSES.IN_PROGRESS
    }

    return SCAN_STATUSES.COMPLETED
  }, [scanData])

  const isButtonHidden = useMemo(() => {
    return scanData?.inProgress || scanStatus === SCAN_STATUSES.PREPARING || !scanData?.id || isShareSecretSet
  }, [scanData, scanStatus, isShareSecretSet])

  const onCloseMoreOptionsMenu = useCallback(() => {
    setState({ anchorOptionsMenu: undefined })
  }, [])

  const onToggleDeleteDialog = useCallback(() => {
    if (!isDeleteInProgress) {
      toggleDeleteDialog()
    }
  }, [isDeleteInProgress, toggleDeleteDialog])

  const onDeleteScan = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.DELETE_REPORT, { accessToken: accessToken?.id })
    dispatch(deleteEtsReport())
  }, [dispatch, accessToken])

  const moreInfoButtonMenuOptions = useMemo(() => {
    return {
      iconButtonProps: {
        onClick: (e: React.SyntheticEvent) => {
          setState({ anchorOptionsMenu: e.currentTarget })
        }
      } as IconButtonProps,
      menuProps: {
        anchorEl: state.anchorOptionsMenu,
        open: Boolean(state.anchorOptionsMenu),
        onClose: onCloseMoreOptionsMenu
      } as MenuProps,
      menuItems: [
        {
          id: 'delete_scan',
          disabled: scanData?.inProgress || !scanData?.id,
          action: () => {
            onCloseMoreOptionsMenu()
            onToggleDeleteDialog()
          }
        }
      ] as OptionsMenu[]
    }
  }, [onToggleDeleteDialog, scanData, state, onCloseMoreOptionsMenu])

  const isAccessTokenSecretActive = useMemo(() => {
    return !!accessToken?.reportSecret?.active && !!isShareValid
  }, [accessToken, isShareValid])

  const onShare = useCallback(() => {
    if (isAccessTokenSecretActive) {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_DISABLED, { accessToken: accessToken?.id })
      dispatch(deactivateReportSharing())
    } else {
      analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_LINK_ENABLED, { accessToken: accessToken?.id })
      dispatch(activateReportSharing())
    }
  }, [dispatch, isAccessTokenSecretActive, accessToken])

  const sendShareTrackingEvent = useCallback(() => {
    analyticsLib.trackAppEvent(analyticsLib.EVENTS.SHARE_REPORT, { accessToken: accessToken?.id })
  }, [accessToken])

  const getReportShareLink = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenShareLink(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  const getShareLinkExpiration = useCallback(() => {
    return isAccessTokenSecretActive ? accessTokenLib.getAccessTokenSecretExpires(accessToken?.id) : ''
  }, [isAccessTokenSecretActive, accessTokenLib, accessToken])

  return useMemo(
    () => [
      {
        error,
        fixTableHeight,
        getSectionElement,
        getShareLinkExpiration,
        getReportShareLink,
        inProgress,
        isButtonHidden,
        isDeleteDialogOpened,
        isDeleteInProgress,
        isShareChecked: isAccessTokenSecretActive,
        isShareInProgress,
        isUserInputDisabledForTable,
        moreInfoButtonMenuOptions,
        onDeleteScan,
        onShare,
        onToggleDeleteDialog,
        scan: scanData,
        scanStatus,
        scrollToSection,
        sendShareTrackingEvent
      }
    ],
    [
      error,
      fixTableHeight,
      getReportShareLink,
      getSectionElement,
      getShareLinkExpiration,
      inProgress,
      isAccessTokenSecretActive,
      isButtonHidden,
      isDeleteDialogOpened,
      isDeleteInProgress,
      isShareInProgress,
      isUserInputDisabledForTable,
      moreInfoButtonMenuOptions,
      onDeleteScan,
      onShare,
      onToggleDeleteDialog,
      scanData,
      scanStatus,
      scrollToSection,
      sendShareTrackingEvent
    ]
  )
}
