import React, { useMemo } from 'react'

import { Typography, Grid, IconButton, Popover, MenuItem, Fade } from '@barracuda-internal/bds-core'
import { MenuVertical as MenuVerticalIcon } from '@barracuda-internal/bds-core/dist/Icons/Core'

import LinearProgress from 'global/components/lib/linearProgress/LinearProgress'
import { useFormatMessage } from 'global/lib/localization'

import DashboardAttackingDomains from 'ets/components/pages/dashboard/attackingDomains/DashboardAttackingDomains'
import DashboardDomains from 'ets/components/pages/dashboard/domains/DashboardDomains'
import DashboardEmployees from 'ets/components/pages/dashboard/employees/DashboardEmployees'
import DashboardHeader from 'ets/components/pages/dashboard/header/DashboardHeader'
import DashboardMenu from 'ets/components/pages/dashboard/menu/DashboardMenu'
import DashboardOverview from 'ets/components/pages/dashboard/overview/DashboardOverview'
import DashboardThreats from 'ets/components/pages/dashboard/threats/DashboardThreats'
import useDashboardLogic, { TABS, APP_HEADER_HEIGHT } from 'ets/components/pages/dashboard/useDashboardLogic'
import styles from 'ets/components/pages/dashboard/dashboardStyles'
import Layout from 'ets/components/lib/layout/Layout'
import FreeTrialDialog from 'ets/components/lib/dialogs/freeTrialDialog/FreeTrialDialog'
import ShareMenu from 'ets/components/pages/dashboard/shareMenu/ShareMenu'
import DeleteDialog from 'ets/components/lib/dialogs/deleteDialog/DeleteDialog'
import DEFAULTS from 'ets/configs/styles/defaults'

const BASE_I18N_KEY = 'ets.app.dashboard'

export const Dashboard: React.FC = () => {
  const formatMessage = useFormatMessage(BASE_I18N_KEY)
  const classes = styles()
  const [dashboardLogic] = useDashboardLogic()

  return useMemo(() => {
    const {
      fixTableHeight,
      getSectionElement,
      inProgress,
      isButtonHidden,
      isDeleteDialogOpened,
      isDeleteInProgress,
      isUserInputDisabledForTable,
      moreInfoButtonMenuOptions,
      onDeleteScan,
      onToggleDeleteDialog,
      scan,
      scanStatus,
      scrollToSection
    } = dashboardLogic

    return (
      // TODO BNFIR-4366 add logic to change the pageTitle to new scan if the new scan tab is active
      <Layout
        pageTitle={formatMessage('page_title.scan_summary')}
        rightContent={
          <>
            <ShareMenu />
            <FreeTrialDialog />
            <>
              <IconButton
                className={isButtonHidden ? classes.hideMastheadButton : undefined}
                data-action="more-options"
                {...moreInfoButtonMenuOptions.iconButtonProps}
              >
                <MenuVerticalIcon />
              </IconButton>
              <Popover
                style={{ zIndex: DEFAULTS.DIALOGS_ZINDEX }}
                keepMounted
                {...moreInfoButtonMenuOptions.menuProps}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                PaperProps={{
                  style: {
                    width: '15ch'
                  }
                }}
              >
                {moreInfoButtonMenuOptions.menuItems.map(menuItem => (
                  <MenuItem
                    key={menuItem.id}
                    onClick={menuItem.action}
                    disabled={menuItem.disabled}
                    data-field="menu-item"
                  >
                    {formatMessage(menuItem.id)}
                  </MenuItem>
                ))}
              </Popover>

              <DeleteDialog
                data-field="delete-dialog"
                toggleDialog={onToggleDeleteDialog}
                isDialogOpened={isDeleteDialogOpened}
                onDeleteScan={onDeleteScan}
                isDeleteInProgress={isDeleteInProgress}
              />
            </>
          </>
        }
      >
        {inProgress && <LinearProgress />}
        <Grid container alignItems="center" justifyContent="center" data-field="grid-root">
          <Grid container alignItems="center" justifyContent="center" direction="column" data-field="grid-inner-root">
            <div className={classes.innerRoot}>
              <DashboardHeader scanStatus={scanStatus} scan={scan} />
            </div>
            <DashboardMenu
              TABS={TABS}
              scrollToSection={scrollToSection}
              getSectionElement={getSectionElement}
              APP_HEADER_HEIGHT={APP_HEADER_HEIGHT}
            />
            <div className={`${classes.innerRoot} overview-section`}>
              <DashboardOverview scrollToSection={scrollToSection} />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="employees-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('employees.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardEmployees
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="threats-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('threats.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardThreats
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="domains-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('domains.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardDomains
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
            <Grid className="attacks-section" container justifyContent="center">
              <Typography className={classes.title}>{formatMessage('attacks.title')}</Typography>
            </Grid>
            <div className={classes.innerRoot}>
              <DashboardAttackingDomains
                isUserInputDisabledForTable={isUserInputDisabledForTable}
                fixTableHeight={fixTableHeight}
              />
            </div>
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.separator} />
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    )
  }, [dashboardLogic, classes, formatMessage])
}

export default Dashboard
